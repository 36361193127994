import ApiService from "./api.service";
import objectToFormData from "@/helpers";

const UsuarioService = {
  async getUsuarios(params) {
    return await ApiService.get("/usuarios", params);
  },

  async getUsuario(id) {
    return await ApiService.get(`/usuarios/${id}`);
  },

  async salvar(usuario) {
    return await ApiService.postFormData(
      "/usuarios/salvar",
      objectToFormData(usuario)
    );
  },

  async alterarSenha(usuario) {
    return await ApiService.post("/usuarios/alterar-senha", usuario);
  },

  async excluir(id) {
    return await ApiService.delete(`/usuarios/excluir/${id}`);
  },
};

export default UsuarioService;
