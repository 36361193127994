import ApiService from "./api.service";

const FormularioService = {
  async getFormularios(params) {
    return await ApiService.get("/formularios", params);
  },

  async getFormulario(id) {
    return await ApiService.get(`/formularios/${id}`);
  },

  async imprimirFormulario(id) {
    return await ApiService.getBlob(`/formularios/imprimir/${id}`);
  },
};

export default FormularioService;
