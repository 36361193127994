import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import router from "@/router";
import Swal from "sweetalert2";
import { DADOS_AUTENTICACAO } from "@/core/services/store/autenticacao.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    Vue.axios.interceptors.response.use(
      (response) => {
        // if (response.config.headers.showOverlay) {
        //   store.dispatch(SHOW_OVERLAY, false);
        // }

        // const refreshedToken =
        //   response.headers.authorization?.split("Bearer ")[1];

        // if (refreshedToken) {
        //   const data = moment().format("YYYY-MM-DD HH:mm:ss");
        //   JwtService.saveToken(refreshedToken, data);
        //   this.setHeader();
        // }

        return response;
      },
      (error) => {
        if (error.response) {
          const statusCode = error.response.status;
          const data = error.response.data;
          const errorMessage =
            data.message || data.mensagem || error.response.statusText;

          switch (statusCode) {
            case 400:
            case 403:
            case 404:
            case 500:
              Swal.fire({
                title: "ERRO",
                text: errorMessage,
                icon: "error",
                heightAuto: false,
              });

              break;
            case 401:
              store.dispatch(DADOS_AUTENTICACAO, false).then(() => {
                router.push({ name: "autenticar" }).then(() => {
                  const mensagem = errorMessage
                    ? errorMessage
                    : "Sua conta foi deslogada, informe suas credenciais novamente!";

                  if (errorMessage) {
                    Swal.fire({
                      title: "ERRO",
                      text: mensagem,
                      icon: "error",
                      heightAuto: false,
                    });
                  }
                });
              });

              break;
            case 422:
              break;
            default:
              Swal.fire({
                title: "ERRO",
                text: "Houve um erro com a sua solicitação.",
                icon: "error",
                heightAuto: false,
              });
              break;
          }
        } else if (error.request) {
          Swal.fire({
            title: "ERRO",
            text: "Houve um erro com a sua solicitação ou com a conexão com o servidor.",
            icon: "error",
            heightAuto: false,
          });
        }

        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.obterToken()}`;
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, params) {
    return Vue.axios
      .get(`${resource}`, {
        params: params,
      })
      .catch((error) => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the GET HTTP request with blob response
   * @param resource
   * @param slug
   * @returns {*}
   */
  getBlob(resource) {
    return Vue.axios
      .get(`${resource}`, {
        responseType: "blob",
      })
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Set the POST HTTP request with form data
   * @param resource
   * @param params
   * @returns {*}
   */
  postFormData(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
